import {
  FrmFeaturePermission,
  OperationalFeaturePermission,
  OperationalPermissionsModuleAccess
} from '@ifhms/admin/web/domain/permissions';
import { NavListBaseItem } from '@ifhms/common/angular/features/navigation';
import { ReportItemType } from '@ifhms/common/angular/features/reports/shared';

export const getOperationNavigationMenuItems = (
  companySlug: string,
  feedlotSlug: string
): NavListBaseItem[] => [
  {
    key: 'module-enablement',
    icon: 'fact_check',
    routerLink: [
      `/operation/${companySlug}/detail/${feedlotSlug}/module-enablement`
    ],
    permissionList: [OperationalFeaturePermission.IsCompanyAdmin]
  },
  {
    key: 'overview',
    icon: 'dashboard',
    active: false,
    routerLink: [`/operation/${companySlug}/detail/${feedlotSlug}/overview`]
  },
  {
    key: 'user-management',
    icon: 'person_outline',
    active: false,
    permissionList: [
      OperationalFeaturePermission.UsersView,
      OperationalFeaturePermission.RolesView
    ],
    subItems: [
      {
        key: 'users',
        routerLink: [
          `/operation/${companySlug}/detail/${feedlotSlug}/user-management/users`
        ],
        permissionList: [OperationalFeaturePermission.UsersView]
      },
      {
        key: 'roles',
        routerLink: [
          `/operation/${companySlug}/detail/${feedlotSlug}/user-management/roles`
        ],
        permissionList: [OperationalFeaturePermission.RolesView]
      }
    ]
  },
  {
    key: 'reference-data',
    icon: 'data_object',
    active: false,
    routerLink: [
      `/operation/${companySlug}/detail/${feedlotSlug}/reference-data`
    ],
    permissionList: [OperationalFeaturePermission.ReferenceDataView]
  },
  {
    key: 'offline-access',
    icon: 'cloud_download',
    active: false,
    isVisible: false,
    routerLink: [
      `/operation/${companySlug}/detail/${feedlotSlug}/offline-access/data-migration`
    ]
  },
  // {
  //   key: 'enterprise-settings',
  //   icon: 'settings'
  // },
  {
    key: 'divider',
    permissionList: [OperationalFeaturePermission.SettingsGeneralView]
  },
  // {
  //   key: 'enterprise-settings',
  //   icon: 'settings'
  // },
  {
    key: 'operation-settings',
    icon: 'settings',
    active: false,
    permissionList: [OperationalFeaturePermission.SettingsGeneralView],
    subItems: [
      {
        key: 'feedlot-info',
        routerLink: [
          `/operation/${companySlug}/detail/${feedlotSlug}/operation-settings/feedlot-info`
        ],
        permissionList: [OperationalFeaturePermission.SettingsGeneralView]
      }
    ]
  },
  {
    key: 'divider',
    permissionList: [OperationalFeaturePermission.FRM]
  },
  {
    key: 'frm',
    icon: 'vaccines',
    permissionList: [OperationalFeaturePermission.FRM],
    subItems: [
      {
        key: 'lot-billing',
        active: false,
        customIcon: 'lot-billing',
        routerLink: [
          `/operation/${companySlug}/detail/${feedlotSlug}/frm/lot-billing`
        ],
        permissionList: [FrmFeaturePermission.LotBillingView]
      },
      {
        key: 'tag-mgmt',
        customIcon: 'tag-mgmt',
        subItems: [
          {
            key: 'tag-settings',
            routerLink: [
              `/operation/${companySlug}/detail/${feedlotSlug}/frm/tag-mgmt/tag-settings`
            ],
            permissionList: [
              FrmFeaturePermission.SettingsTagView
            ]
          },
          {
            key: 'electronic-tags-settings',
            routerLink: [
              `/operation/${companySlug}/detail/${feedlotSlug}/frm/tag-mgmt/electronic-tags-settings`
            ],
            permissionList: [
              FrmFeaturePermission.SettingsElectronicTagView
            ]
          }
        ]
      },
      {
        key: 'reports',
        icon: 'insert_chart',
        active: false,
        permissionList: [
          FrmFeaturePermission.ReportsAnimalTreatmentHistory,
          FrmFeaturePermission.ReportsLotUFDetail,
          FrmFeaturePermission.ReportsOtherDiseasesManagement,
          FrmFeaturePermission.ReportsPostMortemDetail,
          FrmFeaturePermission.ReportsPostMortemDetailByDate,
          FrmFeaturePermission.ReportsUFManagement,
          FrmFeaturePermission.ReportsCattleMovement,
          FrmFeaturePermission.ReportsEventDetails,
          FrmFeaturePermission.ReportsHospitalOccupancy,
          FrmFeaturePermission.ReportsLotExpensesSummary,
          FrmFeaturePermission.ReportsLotHealthSummary,
          FrmFeaturePermission.ReportsLotOtherDiseasesDetail,
          FrmFeaturePermission.ReportsLotInventoryReport,
          FrmFeaturePermission.ReportsPenInventory,
          FrmFeaturePermission.ReportsTagAvailability,
          FrmFeaturePermission.ReportsWithdrawal,
          FrmFeaturePermission.ReportsCurrentProductPricing,
          FrmFeaturePermission.ReportsProductOnHand,
          FrmFeaturePermission.ReportsProductUsage,
          FrmFeaturePermission.ReportsRailDetail,
          FrmFeaturePermission.ReportsReconciledPhysicalInventoryValue,
          FrmFeaturePermission.ReportsArrivalWorkOrder,
          FrmFeaturePermission.ReportsLotTransferWorkOrder,
          FrmFeaturePermission.ReportsMovementWorkOrder,
          FrmFeaturePermission.ReportsRailWorkOrder,
          FrmFeaturePermission.ReportsReHandlingWorkOrder,
          FrmFeaturePermission.ReportsShipmentWorkOrder
        ],
        subItems: [
          {
            key: 'animal-health',
            icon: 'health_and_safety',
            subItems: [
              {
                key: ReportItemType.AnimalTreatmentHistory,
                label: 'Treatment History',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/animal-health/animal-treatment-history`
                ]
              },
              {
                key: ReportItemType.LotTreatmentHistory,
                label: 'Lot Treatment History',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/animal-health/lot-treatment-history`
                ]
              },
              {
                key: ReportItemType.LotOtherDiseasesDetail,
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/animal-health/lot-other-diseases-detail`
                ]
              },
              {
                key: ReportItemType.LotUFDetail,
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/animal-health/lot-uf-detail-report`
                ]
              },
              {
                key: ReportItemType.OtherDiseasesMgmt,
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/animal-health/other-diseases-management`
                ]
              },
              {
                key: ReportItemType.PostMortemDetail,
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/animal-health/post-mortem-detail`
                ]
              },
              {
                key: ReportItemType.PostMortemDetailByDate,
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/animal-health/post-mortem-detail-by-date`
                ]
              },
              {
                key: ReportItemType.RailDetailByDate,
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/animal-health/rail-detail-by-date`
                ]
              },
              {
                key: ReportItemType.UFManagement,
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/animal-health/uf-management`
                ]
              }
            ]
          },
          {
            key: 'operational',
            icon: 'work_outline',
            subItems: [
              {
                key: ReportItemType.CattleMovement,
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/operational/cattle-movement`
                ]
              },
              {
                key: ReportItemType.EventDetails,
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/operational/event-details`
                ]
              },
              {
                key: ReportItemType.HospitalOccupancy,
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/operational/hospital-occupancy`
                ]
              },
              {
                key: ReportItemType.LotExpenseSummary,
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/operational/lot-expense-summary`
                ]
              },
              {
                key: ReportItemType.LotHealthSummary,
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/operational/lot-health-summary`
                ]
              },
              {
                key: ReportItemType.LotInventory,
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/operational/lot-inventory`
                ]
              },
              {
                key: ReportItemType.PenInventory,
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/operational/pen-inventory`
                ]
              },
              {
                key: ReportItemType.TagAvailability,
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/operational/tag-availability`
                ]
              },
              {
                key: ReportItemType.WithdrawalSummaryReport,
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/operational/withdrawal-summary-report`
                ]
              }
            ]
          },
          {
            key: 'product-inventory',
            icon: 'production_quantity_limits',
            subItems: [
              {
                key: ReportItemType.CurrentProductPricing,
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/product-inventory/current-product-pricing`
                ]
              },
              {
                key: ReportItemType.ProductOnHand,
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/product-inventory/product-on-hand`
                ]
              },
              {
                key: ReportItemType.ProductUsage,
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/product-inventory/product-usage`
                ]
              },
              {
                key: ReportItemType.ReconciledPhysicalInventoryValue,
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/product-inventory/reconciled-physical-inventory-value`
                ]
              }
            ]
          },
          {
            key: 'work-orders-events',
            icon: 'event_note',
            subItems: [
              {
                key: ReportItemType.ArrivalWorkOrder,
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/work-orders-events/arrival-work-order`
                ]
              },
              {
                key: ReportItemType.LotTransferWorkOrder,
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/work-orders-events/lot-transfer-work-order`
                ]
              },
              {
                key: ReportItemType.MovementWorkOrder,
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/work-orders-events/movement-work-order`
                ]
              },
              {
                key: ReportItemType.RailWorkOrder,
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/work-orders-events/rail-work-order`
                ]
              },
              {
                key: ReportItemType.RehandlingWorkOrder,
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/work-orders-events/rehandling-work-order`
                ]
              },
              {
                key: ReportItemType.ShipmentWorkOrder,
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/work-orders-events/shipment-work-order`
                ]
              },
              {
                key: ReportItemType.TransferOutWorkOrder,
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/work-orders-events/transfer-out-work-order`
                ]
              },
              {
                key: ReportItemType.TreatmentEvent,
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/work-orders-events/treatment-event`
                ]
              },
              {
                key: ReportItemType.PostMortemEvent,
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/work-orders-events/post-mortem-event`
                ]
              }
            ]
          },
          {
            key: 'exports',
            icon: 'upgrade',
            subItems: [
              {
                key: ReportItemType.ExportWeightByWorkOrder,
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/exports/export-weight-by-work-order`
                ]
              },
              {
                key: ReportItemType.ExportWeightByTag,
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/exports/export-weight-by-tag`
                ]
              },
              {
                key: ReportItemType.ExportBirthdayByTag,
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/exports/export-birthday-by-tag`
                ]
              },
              {
                key: ReportItemType.ExportCciaData,
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/exports/export-ccia-data`
                ]
              },
              {
                key: ReportItemType.ExportCustomFieldsByTag,
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/exports/export-custom-fields-by-tag`
                ]
              },
              {
                key: ReportItemType.ExportLotBilling,
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/exports/export-lot-billing`
                ]
              }
            ]
          }
        ]
      },
      {
        key: 'product-management',
        active: false,
        customIcon: 'product-mgmt',
        permissionList: [
          FrmFeaturePermission.ProductDefinitionView,
          FrmFeaturePermission.ProductMappingView,
          FrmFeaturePermission.ProductPricingView,
          FrmFeaturePermission.ProductReceiptView,
          FrmFeaturePermission.ProductReconciliationView,
          FrmFeaturePermission.ProductLotNoExpiryView,
          FrmFeaturePermission.ProductTransactionView
        ],
        subItems: [
          {
            key: 'products',
            routerLink: [
              `/operation/${companySlug}/detail/${feedlotSlug}/frm/product-management/products`
            ],
            permissionList: [FrmFeaturePermission.ProductDefinitionView]
          },
          {
            key: 'receipts',
            routerLink: [
              `/operation/${companySlug}/detail/${feedlotSlug}/frm/product-management/receipts/overview`
            ],
            permissionList: [FrmFeaturePermission.ProductReceiptView]
          },
          {
            key: 'reconciliation',
            routerLink: [
              `/operation/${companySlug}/detail/${feedlotSlug}/frm/product-management/reconciliation/overview`
            ],
            permissionList: [FrmFeaturePermission.ProductReconciliationView]
          }
        ]
      },
      {
        key: 'protocols',
        customIcon: 'protocols',
        routerLink: [
          `/operation/${companySlug}/detail/${feedlotSlug}/frm/protocols/treatment-protocols`
        ]
        // TODO THERE IS A ROUTING MODULE CREATED FOR PROTOCOLS, IF MORE THAN ONE ITEM IN THIS SECTION USE PROTOCOLS MODULE FOR ROUTING
        // AND ABOVE LINK SHOULD BE FRM/PROTOCOLS
        // subItems: [
        //   {
        //     key: 'treatment-protocols',
        //     active: false,
        //     routerLink: [
        //       `/operation/${companySlug}/feedlot/${feedlotSlug}/frm/protocols/treatment-protocols`
        //     ],
        //     permissionList: [OperationFeaturePermission.SettingsPostMortemRead]
        //   }
        // ]
      },
      {
        key: 'charges',
        customIcon: 'charges',
        subItems: [
          {
            key: 'labour-charges',
            routerLink: [
              `/operation/${companySlug}/detail/${feedlotSlug}/frm/charges/labour-charges`
            ],
            permissionList: [FrmFeaturePermission.SettingsLabourChargesView]
          },
          {
            key: 'procedure-charges',
            routerLink: [
              `/operation/${companySlug}/detail/${feedlotSlug}/frm/charges/procedure-charges`
            ],
            permissionList: [FrmFeaturePermission.SettingsProcedureChargesView]
          },
          {
            key: 'other-fees',
            routerLink: [
              `/operation/${companySlug}/detail/${feedlotSlug}/frm/charges/other-fees`
            ],
            permissionList: [FrmFeaturePermission.SettingsOtherFeesView]
          }
        ]
      },
      {
        key: 'data-imports',
        customIcon: 'data-imports',
        routerLink: [
          `/operation/${companySlug}/detail/${feedlotSlug}/frm/data-imports/import-animals`
        ]
        // TODO THERE IS A ROUTING MODULE CREATED FOR DATA-IMPORTS, IF MORE THAN ONE ITEM IN THIS SECTION USE DATA IMPORTS MODULE FOR ROUTING
        // AND ABOVE LINK SHOULD BE FRM/DATA-IMPORTS
        // subItems: [
        //   {
        //     key: 'animal-import',
        //     routerLink: [
        //       `/operation/${companySlug}/feedlot/${feedlotSlug}/frm/data-imports/import-animals`
        //     ],
        //     permissionList: [OperationFeaturePermission.SettingsGeneralRead]
        //   }
        // ]
      },
      {
        key: 'integrations',
        customIcon: 'integrations',
        subItems: [
          {
            key: 'ccia',
            routerLink: [
              `/operation/${companySlug}/detail/${feedlotSlug}/frm/integrations/ccia-integration`
            ],
            permissionList: [FrmFeaturePermission.SettingsCCIAView]
          },
          // {
          //   key: 'post-mortem',
          //   routerLink: [
          //     `/operation/${companySlug}/feedlot/${feedlotSlug}/frm/integrations/post-mortem-integration`
          //   ],
          //   permissionList: [OperationFeaturePermission.SettingsCCIARead]
          // },
          {
            key: 'turnkey',
            routerLink: [
              `/operation/${companySlug}/detail/${feedlotSlug}/frm/integrations/turnkey-integration`
            ]
          },
          {
            key: 'pro-feeder',
            routerLink: [
              `/operation/${companySlug}/detail/${feedlotSlug}/frm/integrations/pro-feeder`
            ]
          }
        ]
      },
      {
        key: 'frm-settings',
        icon: 'settings',
        permissionList: [
          FrmFeaturePermission.SettingsPricingSettingsView,
          FrmFeaturePermission.SettingsDefaultValuesView,
          FrmFeaturePermission.SettingsTargetWeightView,
          FrmFeaturePermission.SettingsArrivalView,
          FrmFeaturePermission.SettingsReHandlingView,
          FrmFeaturePermission.SettingsPostMortemView,
          FrmFeaturePermission.SettingsZeroScaleReminderView,
          FrmFeaturePermission.TreatmentProtocolSettingsView,
          FrmFeaturePermission.SettingsPostMortemView,
          FrmFeaturePermission.SettingsCustomFieldsManagementView,
          FrmFeaturePermission.SettingsSortGroupView,
          FrmFeaturePermission.SettingsSortGroupTemplatesView
        ],
        subItems: [
          {
            key: 'global-settings',
            customIcon: 'global',
            permissionList: [
              FrmFeaturePermission.SettingsPricingSettingsView,
              FrmFeaturePermission.SettingsDefaultValuesView,
              FrmFeaturePermission.SettingsTargetWeightView
            ],
            subItems: [
              {
                key: 'pricing',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/frm-settings/global-settings/pricing`
                ],
                permissionList: [
                  FrmFeaturePermission.SettingsPricingSettingsView
                ]
              },
              {
                key: 'global-default',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/frm-settings/global-settings/default-values`
                ],
                permissionList: [FrmFeaturePermission.SettingsDefaultValuesView]
              },
              {
                key: 'global-target',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/frm-settings/global-settings/target-weights/overview`
                ],
                permissionList: [FrmFeaturePermission.SettingsTargetWeightView]
              }
            ]
          },
          {
            key: 'events-work-orders',
            customIcon: 'work-order-events',
            permissionList: [
              FrmFeaturePermission.SettingsArrivalView,
              FrmFeaturePermission.SettingsReHandlingView,
              FrmFeaturePermission.SettingsPostMortemView,
              FrmFeaturePermission.SettingsZeroScaleReminderView,
              FrmFeaturePermission.TreatmentProtocolSettingsView,
              FrmFeaturePermission.SettingsPostMortemView
            ],
            subItems: [
              {
                key: 'arrival',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/frm-settings/events-work-orders/arrival`
                ],
                permissionList: [FrmFeaturePermission.SettingsArrivalView]
              },
              {
                key: 'rehandling',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/frm-settings/events-work-orders/rehandling`
                ],
                permissionList: [FrmFeaturePermission.SettingsReHandlingView]
              },
              {
                key: 'adg-settings',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/frm-settings/events-work-orders/adg-settings`
                ],
                permissionList: [FrmFeaturePermission.SettingsPostMortemView]
              },
              {
                key: 'zero-scale-reminder',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/frm-settings/events-work-orders/zero-scale-reminder`
                ],
                permissionList: [
                  FrmFeaturePermission.SettingsZeroScaleReminderView
                ]
              },
              {
                key: 'treatment',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/frm-settings/events-work-orders/treatment-protocol`
                ],
                permissionList: [
                  FrmFeaturePermission.TreatmentProtocolSettingsView,
                  FrmFeaturePermission.TreatmentProtocolSettingsEdit,
                  'enterprise'
                ]
              },
              {
                key: 'post-mortem',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/frm-settings/events-work-orders/post-mortem`
                ],
                permissionList: [FrmFeaturePermission.SettingsPostMortemView]
              }
            ]
          },
          {
            key: 'custom-fields',
            customIcon: 'custom-fields',
            routerLink: [
              `/operation/${companySlug}/detail/${feedlotSlug}/frm/frm-settings/custom-fields`
            ],
            permissionList: [
              FrmFeaturePermission.SettingsCustomFieldsManagementView
            ]
            // TODO THERE IS A ROUTING MODULE CREATED FOR CUSTOM FIELDS, IF MORE THAN ONE ITEM IN THIS SECTION USE CUSTOM FIELDS MODULE FOR ROUTING
            // subItems: [
            //   {
            //     key: 'custom-fields-mgmt',
            //     routerLink: [
            //       `/operation/${companySlug}/feedlot/${feedlotSlug}/frm/settings/custom-fields`
            //     ],
            //     permissionList: [
            //       OperationFeaturePermission.SettingsCustomFieldsManagementRead
            //     ]
            //   }
            // ]
          },
          {
            key: 'sort-group',
            customIcon: 'sort-group',
            permissionList: [
              FrmFeaturePermission.SettingsSortGroupView,
              FrmFeaturePermission.SettingsSortGroupTemplatesView
            ],
            subItems: [
              {
                key: 'sort-group-settings',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/frm-settings/sort-group/sort-group-settings`
                ],
                permissionList: [FrmFeaturePermission.SettingsSortGroupView]
              },
              {
                key: 'sort-group-templates',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/frm-settings/sort-group/sort-group-templates`
                ],
                permissionList: [
                  FrmFeaturePermission.SettingsSortGroupTemplatesView
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  {
    key: 'procurement',
    icon: 'content_paste_search',
    routerLink: [`/operation/${companySlug}/detail/${feedlotSlug}/procurement`],
    permissionList: [OperationalPermissionsModuleAccess.Procurement],
    subItems: [
      {
        key: 'procurement-settings',
        routerLink: [
          `/operation/${companySlug}/detail/${feedlotSlug}/procurement`
        ],
        permissionList: [OperationalFeaturePermission.SettingsGeneralView]
      }
    ]
  }
  // {
  //   key: 'close-out',
  //   icon: 'point_of_sale',
  //   subItems: [
  //     {
  //       key: 'settings'
  //     }
  //   ]
  // },
  // {
  //   key: 'psmt',
  //   icon: 'price_check',
  //   subItems: [
  //     {
  //       key: 'settings'
  //     },
  //     {
  //       key: 'cattle-type'
  //     }
  //   ]
  // },
  // {
  //   key: 'post-mortem',
  //   icon: 'medical_services',
  //   subItems: [
  //     {
  //       key: 'settings'
  //     }
  //   ]
  // },
];

export enum ReportPermissionsEnum {
  ReportsAnimalTreatmentHistory = 'reports.canViewAnimalTreatmentHistoryReport',
  ReportsArrivalWorkOrder = 'reports.canViewArrivalWorkOrderReport',
  ReportsCattleMovement = 'reports.canViewCattleMovementReport',
  ReportsCurrentProductPricing = 'reports.canViewCurrentProductPricingReport',
  ReportsDailyTreatmentHistoryByFacility = 'reports.canViewDailyTreatmentHistoryByFacilityReport',
  ReportsEventDetails = 'reports.canViewEventDetailsReport',
  ReportsHospitalOccupancy = 'reports.canViewHospitalOccupancyReport',
  ReportsLotExpensesSummary = 'reports.canViewLotExpensesSummaryReport',
  ReportsLotHealthSummary = 'reports.canViewLotHealthSummaryReport',
  ReportsLotOtherDiseasesDetail = 'reports.canViewLotOtherDiseasesDetailReport',
  ReportsLotTransferWorkOrder = 'reports.canViewLotTransferWorkOrderReport',
  ReportsLotUFDetail = 'reports.canViewLotUfDetailReport',
  ReportsLotInventoryReport = 'reports.canViewLotInventoryReport',
  ReportsMovementWorkOrder = 'reports.canViewMovementWorkOrderReport',
  ReportsOtherDiseasesManagement = 'reports.canViewOtherDiseasesManagementReport',
  ReportsPenInventory = 'reports.canViewPenInventoryReport',
  ReportsPostMortemDetail = 'reports.canViewPostMortemDetailReport',
  ReportsPostMortemDetailByDate = 'reports.canViewPostMortemDetailByDateReport',
  ReportsProductOnHand = 'reports.canViewProductOnHandReport',
  ReportsProductUsage = 'reports.canViewProductUsageReport',
  ReportsRailDetail = 'reports.canViewRailDetailReport',
  ReportsRailWorkOrder = 'reports.canViewRailWorkOrderReport',
  ReportsReconciledPhysicalInventoryValue = 'reports.canViewReconciledPhysicalInventoryValueReport',
  ReportsReHandlingWorkOrder = 'reports.canViewRehandlingWorkOrderReport',
  ReportsShipmentWorkOrder = 'reports.canViewShipmentWorkOrderReport',
  ReportsTransferOutWorkOrder = 'reports.canViewTransferOutWorkOrderReport',
  ReportsTagAvailability = 'reports.canViewTagAvailabilityReport',
  ReportsUFManagement = 'reports.canViewUfManagementReport',
  ReportsWithdrawal = 'reports.canViewWithdrawalReport'
}
    
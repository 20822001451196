import { APP_CONFIG_BASE } from './app-config-base';
import { AppConfig } from '@ifhms/admin/web/core/config';

export const APP_CONFIG_FMS_PRODUCTION: AppConfig = {
  ...APP_CONFIG_BASE,

  environmentName: 'production',
  production: false,
  apiUrl: 'https://admin-api.frm.feedlothealth.com/api',
  adminApiUrl: 'https://admin-api.frm.feedlothealth.com/api',
  signalRUrl: 'https://admin-api.frm.feedlothealth.com/PushNotification',
  showDevFeature: false,
  authServerUrl: 'https://identity.frm.feedlothealth.com',
  feedlotAppUrl: 'https://feedlot.frm.feedlothealth.com',
  migrationProgressSignalRUrl:
  'https://data-sync-api.frm.feedlothealth.com/MigrationProgress',
  dataSyncApiUrl: 'https://data-sync-api.frm.feedlothealth.com/api',
  integrations: {
    heapAppId: '1669860482',
    sentryDsn: 'https://8582deb49f9ccb303620e5247b5cf31f@o4506538482532352.ingest.us.sentry.io/4508015959932928'
  }
};

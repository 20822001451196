export enum FrmPermissionsReport {
  ReportsAnimalTreatmentHistory = 'frmPermission.canViewAnimalTreatmentHistoryReport',
  ReportsArrivalWorkOrder = 'frmPermission.canViewArrivalWorkOrderReport',
  ReportsCattleMovement = 'frmPermission.canViewCattleMovementReport',
  ReportsCurrentProductPricing = 'frmPermission.canViewCurrentProductPricingReport',
  ReportsDailyTreatmentHistoryByFacility = 'frmPermission.canViewDailyTreatmentHistoryByFacilityReport',
  ReportsEventDetails = 'frmPermission.canViewEventDetailsReport',
  ReportsHospitalOccupancy = 'frmPermission.canViewHospitalOccupancyReport',
  ReportsLotExpensesSummary = 'frmPermission.canViewLotExpensesSummaryReport',
  ReportsLotHealthSummary = 'frmPermission.canViewLotHealthSummaryReport',
  ReportsLotOtherDiseasesDetail = 'frmPermission.canViewLotOtherDiseasesDetailReport',
  ReportsLotTransferWorkOrder = 'frmPermission.canViewLotTransferWorkOrderReport',
  ReportsLotUFDetail = 'frmPermission.canViewLotUfDetailReport',
  ReportsLotInventoryReport = 'frmPermission.canViewLotInventoryReport',
  ReportsMovementWorkOrder = 'frmPermission.canViewMovementWorkOrderReport',
  ReportsOtherDiseasesManagement = 'frmPermission.canViewOtherDiseasesManagementReport',
  ReportsPenInventory = 'frmPermission.canViewPenInventoryReport',
  ReportsPostMortemDetail = 'frmPermission.canViewPostMortemDetailReport',
  ReportsPostMortemDetailByDate = 'frmPermission.canViewPostMortemDetailByDateReport',
  ReportsProductOnHand = 'frmPermission.canViewProductOnHandReport',
  ReportsProductUsage = 'frmPermission.canViewProductUsageReport',
  ReportsRailDetail = 'frmPermission.canViewRailDetailReport',
  ReportsRailWorkOrder = 'frmPermission.canViewRailWorkOrderReport',
  ReportsReconciledPhysicalInventoryValue = 'frmPermission.canViewReconciledPhysicalInventoryValueReport',
  ReportsReHandlingWorkOrder = 'frmPermission.canViewRehandlingWorkOrderReport',
  ReportsShipmentWorkOrder = 'frmPermission.canViewShipmentWorkOrderReport',
  ReportsTagAvailability = 'frmPermission.canViewTagAvailabilityReport',
  ReportsUFManagement = 'frmPermission.canViewUfManagementReport',
  ReportsWithdrawal = 'frmPermission.canViewWithdrawalReport',
  ReportsPhysicalInventoryWorksheet = 'frmPermission.canViewPhysicalInventoryWorksheetReport'
}

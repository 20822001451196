import { CompanyFeaturePermission } from '@ifhms/admin/web/domain/permissions';
import { SersiNavListItem } from '@sersi/angular/ui/side-nav';
import { ReportItemType } from '@ifhms/common/angular/features/reports/shared';

export const getCompanyNavigationMenuItems = (
  companySlug: string
): SersiNavListItem[] => [
  {
    key: 'operations',
    icon: 'foundation',
    active: false,
    routerLink: [`/company/${companySlug}/operations`]
  },
  {
    key: 'user-management',
    icon: 'person_outline',
    active: false,
    requiredPermission: CompanyFeaturePermission.UserManagement,
    subItems: [
      {
        key: 'users',
        routerLink: [`/company/${companySlug}/user-management/users`],
        permissionList: [CompanyFeaturePermission.UserManagement]
      },
      {
        key: 'roles',
        routerLink: [`/company/${companySlug}/user-management/roles`],
        permissionList: [CompanyFeaturePermission.UserManagement]
      }
    ]
  },
  {
    key: 'reports',
    icon: 'insert_chart',
    active: false,
    requiredPermission: CompanyFeaturePermission.IsAdmin,
    subItems: [
      {
        key: 'animal-health',
        icon: 'health_and_safety',
        subItems: [
          {
            key: ReportItemType.AnimalTreatmentHistory,
            routerLink: [
              `/company/${companySlug}/reports/animal-health/animal-treatment-history`
            ]
          },
          {
            key: ReportItemType.LotTreatmentHistory,
            label: 'Lot Treatment History',
            routerLink: [
              `/company/${companySlug}/reports/animal-health/lot-treatment-history`
            ]
          },
          {
            key: ReportItemType.LotOtherDiseasesDetail,
            routerLink: [
              `/company/${companySlug}/reports/animal-health/lot-other-diseases-detail`
            ]
          },
          {
            key: ReportItemType.LotUFDetail,
            routerLink: [
              `/company/${companySlug}/reports/animal-health/lot-uf-detail-report`
            ]
          },
          {
            key: ReportItemType.OtherDiseasesMgmt,
            routerLink: [
              `/company/${companySlug}/reports/animal-health/other-diseases-management`
            ]
          },
          {
            key: ReportItemType.PostMortemDetail,
            routerLink: [
              `/company/${companySlug}/reports/animal-health/post-mortem-detail`
            ]
          },
          {
            key: ReportItemType.PostMortemDetailByDate,
            routerLink: [
              `/company/${companySlug}/reports/animal-health/post-mortem-detail-by-date`
            ]
          },
          {
            key: ReportItemType.RailDetailByDate,
            routerLink: [
              `/company/${companySlug}/reports/animal-health/rail-detail-by-date`
            ]
          },
          {
            key: ReportItemType.UFManagement,
            routerLink: [
              `/company/${companySlug}/reports/animal-health/uf-management`
            ]
          }
        ]
      },
      {
        key: 'operational',
        icon: 'work_outline',
        subItems: [
          {
            key: ReportItemType.CattleMovement,
            routerLink: [
              `/company/${companySlug}/reports/operational/cattle-movement`
            ]
          },
          {
            key: ReportItemType.EventDetails,
            routerLink: [
              `/company/${companySlug}/reports/operational/event-details`
            ]
          },
          {
            key: ReportItemType.HospitalOccupancy,
            routerLink: [
              `/company/${companySlug}/reports/operational/hospital-occupancy`
            ]
          },
          {
            key: ReportItemType.LotExpenseSummary,
            routerLink: [
              `/company/${companySlug}/reports/operational/lot-expense-summary`
            ]
          },
          {
            key: ReportItemType.LotHealthSummary,
            routerLink: [
              `/company/${companySlug}/reports/operational/lot-health-summary`
            ]
          },
          {
            key: ReportItemType.LotInventory,
            routerLink: [
              `/company/${companySlug}/reports/operational/lot-inventory`
            ]
          },
          {
            key: ReportItemType.PenInventory,
            routerLink: [
              `/company/${companySlug}/reports/operational/pen-inventory`
            ]
          },
          {
            key: ReportItemType.TagAvailability,
            routerLink: [
              `/company/${companySlug}/reports/operational/tag-availability`
            ]
          },
          {
            key: ReportItemType.WithdrawalSummaryReport,
            routerLink: [
              `/company/${companySlug}/reports/operational/withdrawal-summary-report`
            ]
          }
        ]
      },
      {
        key: 'product-inventory',
        icon: 'production_quantity_limits',
        subItems: [
          {
            key: ReportItemType.CurrentProductPricing,
            routerLink: [
              `/company/${companySlug}/reports/product-inventory/current-product-pricing`
            ]
          },
          {
            key: ReportItemType.ProductOnHand,
            routerLink: [
              `/company/${companySlug}/reports/product-inventory/product-on-hand`
            ]
          },
          {
            key: ReportItemType.ProductUsage,
            routerLink: [
              `/company/${companySlug}/reports/product-inventory/product-usage`
            ]
          },
          {
            key: ReportItemType.ReconciledPhysicalInventoryValue,
            routerLink: [
              `/company/${companySlug}/reports/product-inventory/reconciled-physical-inventory-value`
            ]
          }
        ]
      },
      {
        key: 'work-orders-events',
        icon: 'event_note',
        subItems: [
          {
            key: ReportItemType.ArrivalWorkOrder,
            routerLink: [
              `/company/${companySlug}/reports/work-orders-events/arrival-work-order`
            ]
          },
          {
            key: ReportItemType.LotTransferWorkOrder,
            routerLink: [
              `/company/${companySlug}/reports/work-orders-events/lot-transfer-work-order`
            ]
          },
          {
            key: ReportItemType.MovementWorkOrder,
            routerLink: [
              `/company/${companySlug}/reports/work-orders-events/movement-work-order`
            ]
          },
          {
            key: ReportItemType.RailWorkOrder,
            routerLink: [
              `/company/${companySlug}/reports/work-orders-events/rail-work-order`
            ]
          },
          {
            key: ReportItemType.RehandlingWorkOrder,
            routerLink: [
              `/company/${companySlug}/reports/work-orders-events/rehandling-work-order`
            ]
          },
          {
            key: ReportItemType.ShipmentWorkOrder,
            routerLink: [
              `/company/${companySlug}/reports/work-orders-events/shipment-work-order`
            ]
          },
          {
            key: ReportItemType.TransferOutWorkOrder,
            routerLink: [
              `/company/${companySlug}/reports/work-orders-events/transfer-out-work-order`
            ]
          },
          {
            key: ReportItemType.TreatmentEvent,
            routerLink: [
              `/company/${companySlug}/reports/work-orders-events/treatment-event`
            ]
          },
          {
            key: ReportItemType.PostMortemEvent,
            routerLink: [
              `/company/${companySlug}/reports/work-orders-events/post-mortem-event`
            ]
          }
        ]
      },
      {
        key: 'exports',
        icon: 'upgrade',
        subItems: [
          {
            key: ReportItemType.ExportWeightByWorkOrder,
            routerLink: [
              `/company/${companySlug}/reports/exports/export-weight-by-work-order`
            ]
          },
          {
            key: ReportItemType.ExportWeightByTag,
            routerLink: [
              `/company/${companySlug}/reports/exports/export-weight-by-tag`
            ]
          },
          {
            key: ReportItemType.ExportBirthdayByTag,
            routerLink: [
              `/company/${companySlug}/reports/exports/export-birthday-by-tag`
            ]
          },
          {
            key: ReportItemType.ExportCciaData,
            routerLink: [
              `/company/${companySlug}/reports/exports/export-ccia-data`
            ]
          },
          {
            key: ReportItemType.ExportCustomFieldsByTag,
            routerLink: [
              `/company/${companySlug}/reports/exports/export-custom-fields-by-tag`
            ]
          },
          {
            key: ReportItemType.ExportLotBilling,
            routerLink: [
              `/company/${companySlug}/reports/exports/export-lot-billing`
            ]
          }
        ]
      }
    ]
  },
  {
    key: 'product-management',
    icon: 'inventory_2',
    active: false,
    routerLink: [`/company/${companySlug}/product-management/products`],
    requiredPermission: CompanyFeaturePermission.Products
  },
  {
    key: 'reference-data',
    icon: 'data_object',
    active: false,
    routerLink: [`/company/${companySlug}/reference-data`],
    requiredPermission: CompanyFeaturePermission.ReferenceData
  }
];
